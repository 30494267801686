import type { EventOriginValidation } from '@uc/compass-app-bridge';
import type { LDClient } from 'launchdarkly-js-client-sdk';
import get from 'lodash/get';
import {
  computed,
  makeObservable,
  observable,
  observe,
  runInAction,
} from 'mobx';
import type Transaction from 'src/models/transactions/transaction';
import type { AppStore } from 'src/stores/app-store';
import type TransactionStore from 'src/stores/transaction-store';
import { MockLDClient, getLDClient } from 'src/utils/features';
import mockable from 'src/utils/mockable';

interface CustomMessageFlag {
  value: boolean;
  message: string;
}

const AGENT_FEATURE_MAP: Record<string, boolean> = {
  tickets__nav: true,
  transactions__nav: false,
  packets__createGfp: true,
  overview: true,
  documents: true,
  details: true,
  zipform: true,
  items: true,
};

const CLIENT_FEATURE_MAP: Record<string, boolean> = {
  checklists: true,
  // documents: true,
  packets: true,
  tickets__nav: true,
  transactionsHome: true,
};

export default class FeaturesStore {
  @observable variations = new Map<string, unknown>();
  @observable initialized = false;

  parent: AppStore;
  ldClient: LDClient | MockLDClient | null = null;

  constructor(componentParent: AppStore) {
    makeObservable(this);
    this.parent = componentParent;
  }

  get transactions(): TransactionStore {
    return this.parent.transactions;
  }

  initialize(): void {
    getLDClient().then((ldClient) => {
      this.ldClient = ldClient;
      runInAction(() => {
        const allLdFlags = ldClient.allFlags();
        Object.keys(allLdFlags).forEach((k) =>
          this.variations.set(k, allLdFlags[k])
        );
        this.initialized = true;
      });
    });
  }

  // Deprecated: This is for source-hardcoded features that vary by accessMode.
  // Use `variation()` instead.
  isActive = (feature: string): boolean => {
    const accessMode = this.parent.account.accessMode;

    if (accessMode === 'CLIENT') {
      return !!CLIENT_FEATURE_MAP[feature];
    }

    return !!AGENT_FEATURE_MAP[feature];
  };

  variation = mockable(
    'flags',
    'variation',
    <T = string>(key: string, defaultVal = false): T => {
      if (this.initialized) {
        return this.variations.get(key) as T;
      }

      if (
        Object.keys(window.Glide?.CONSTANTS?.DEFAULT_FEATURES || {}).includes(
          key
        )
      ) {
        return window.Glide.CONSTANTS.DEFAULT_FEATURES[key];
      }

      return defaultVal as T;
    }
  );

  // useful for route redirects that depend on a given flag. When loading
  // cold features might not be initialized and the redirect would
  // take the default value, which might not be desirable
  isInitialized(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.initialized) {
        resolve(this.initialized);
      }

      const timeout = setTimeout(() => {
        dispose();
        reject();
      }, 2000);
      const dispose = observe(this, 'initialized', () => {
        dispose();
        clearTimeout(timeout);
        resolve(this.initialized);
      });
    });
  }

  // custom message flags are string flags in which their value being set to anything
  // other than  "false" or "", are considered to be active. If its value is shorter than
  // 5 chars, it's plainly considered it active, but if it's longer, then it returns their
  // string value to be used as custom markdown to potentially display on the UI.
  getCustomMessageFlag(key: string, defaultMessage = ''): CustomMessageFlag {
    const flagVal = this.variation(key) || 'false';
    const value = (flagVal || '').toLowerCase() !== 'false';
    let message = '';

    if (value) {
      message = flagVal.length > 5 ? flagVal : defaultMessage;
    }

    return {
      value,
      message: message || '',
    };
  }

  get pspdfkitViewerFlag(): boolean {
    return true;
  }

  pspdfkitViewerEnabled = (transaction: Transaction): boolean => {
    return this.pspdfkitViewerFlag || Boolean(get(transaction, 'isTms'));
  };

  @computed
  get membershipVerificationEnabled(): boolean {
    const { account, ui } = this.parent;

    return (
      Boolean(this.variation('membership_verification')) &&
      (ui.isEmbedded || account?.user?.accountState !== 'CA')
    );
  }

  @computed
  get associationRecommendationsEnabled(): boolean {
    return Boolean(
      this.variation('membership_verification_association_recommendations')
    );
  }

  @computed
  get pspdfkitFlag(): boolean {
    return Boolean(this.variation('pspdfkitViewerEnabled'));
  }

  @computed
  get formOutlineFlag(): boolean {
    return Boolean(this.variation('form_outline'));
  }

  @computed
  get formConfigOutlineFlag(): boolean {
    return Boolean(this.variation('form_config_outline'));
  }

  @computed
  get formsQA(): boolean {
    return Boolean(this.variation('forms_qa'));
  }

  @computed
  get killChoresFlag_(): CustomMessageFlag {
    return this.getCustomMessageFlag(
      'kill-chores',
      'Notifications are temporarily disabled'
    );
  }

  @computed
  get killChoresFlag(): boolean {
    return this.killChoresFlag_.value;
  }

  @computed
  get killChoresMessage(): string {
    return this.killChoresFlag_.message;
  }

  /** modal-loader url search param. Return `undefined` if param is not provided. */
  modalLoaderParam: string | undefined = new URLSearchParams(
    window.location.search
  )
    .get('modal-loader')
    ?.trim()
    ?.toLowerCase();

  /* TM modal loader feature flag. Set `?modal-loader=1` or `?modal-loader=0` in url to force enable/disable all modals. */
  tmModalEnabled(
    modalName:
      | 'add_property'
      | 'move_doc'
      | 'merge_document'
      | 'apply_templates'
      | 'save_as_template'
      | 'property_picker'
      | 'initial_offer_docs'
      | 'new_associations'
      | 'add_offer_doc_to_trxn'
      | 'void_signature_request'
      | 'cancel_trxn'
      | 'archive_trxn'
      | 'duplicate_trxn'
  ): boolean {
    if (
      window.Glide.env !== 'production' &&
      typeof this.modalLoaderParam === 'string'
    ) {
      return !['0', 'false', 'off'].includes(this.modalLoaderParam);
    }
    return Boolean(this.variation(`${modalName}.tm_modal_ld.20221231`));
  }

  ucFeAuthVariation(flag: string): boolean {
    return Boolean(
      this.variation('uc_fe_auth_entry_path.tm_cn_ld.20221009') &&
        this.variation(flag)
    );
  }

  @computed
  get compassContactsFlag(): boolean {
    return Boolean(this.variation('compass.contacts'));
  }

  @computed
  get disableBppFlag(): boolean {
    return Boolean(this.variation('bpp_disable.tm_offers_02.20220520'));
  }

  @computed
  get enableSideScrollingIndicatorFlag(): boolean {
    return Boolean(
      this.variation('side_scrolling_indicator.tm_offers_ld.20220601')
    );
  }

  @computed
  get offerComparisonsFlag(): boolean {
    return Boolean(this.variation('offer_comparisons.tm_offers_ld.20220412'));
  }

  @computed
  get timelinePDFServerRenderFlag(): boolean {
    return Boolean(this.variation('timeline_pdf_server.tm_offers_ld.20220701'));
  }

  @computed
  get documentHierarchyKillSwitch(): boolean {
    return Boolean(
      this.variation('document_hierarchy_kill_switch.tm_offers_ld.20230520')
    );
  }

  @computed
  get newCompassPublicOfferSummary(): boolean {
    return Boolean(
      this.variation('new_compass_public_offer_summary.tm_offers_ld.20221231')
    );
  }

  @computed
  get enableNonCAContingency(): boolean {
    return Boolean(
      this.variation('enable_non_ca_contingency.tm_offers_ld.20221231')
    );
  }

  @computed
  get noAuthPropertyPortalPage() {
    return Boolean(
      this.variation('no_auth_property_portal_page.tm_offers_ld.20221231')
    );
  }

  @computed
  get adminRbacReal() {
    return Boolean(this.variation('admin_rbac_real.tm_docs_ld.20230430'));
  }

  @computed
  get enableAppBridgeAnalytics() {
    return Boolean(this.variation('app_bridge_analytics.20240430'));
  }

  @computed
  get launchEsignInIframeless() {
    return Boolean(this.variation('launch_esign_in_iframeless'));
  }

  @computed
  get showSigningCertificate() {
    return Boolean(this.parent.embeddedApp.optyFeatures.showSigningCertificate);
  }

  @computed
  get isCrossTeamSharingEnabled() {
    return Boolean(
      this.parent.embeddedApp.optyFeatures.isCrossTeamSharingEnabled
    );
  }

  @computed
  get pspdfkitVersion() {
    return this.variation('pspdfkit_server_version', undefined) as
      | string
      | undefined;
  }

  @computed
  get docsUseProxyDownload() {
    // TODO: TJ-38984 - Remove LD check and use opty as a single source of truth
    // this is a quick and easy hack to be able to test e2e's for secure docs while we
    // support both download types, once we disable direct downloads from s3
    // `this.variation` check should be removed
    return Boolean(
      this.parent.embeddedApp?.optyFeatures?.tmProductsDocsUseProxyDownload ||
        this.variation('docs_use_proxy_download')
    );
  }

  @computed
  get docsUseProxyDownloadV2() {
    // TODO: TJ-38984 - Remove LD check and use opty as a single source of truth
    return Boolean(
      this.parent.embeddedApp?.optyFeatures?.tmProductsDocsUseProxyDownloadV2 ||
        this.variation('docs_use_proxy_download_v2')
    );
  }

  get docsUseProxyUpload() {
    // TODO: TJ-38984 - Remove LD check and use opty as a single source of truth
    return Boolean(
      this.parent.embeddedApp?.optyFeatures?.tmProductsDocsUseProxyUpload ||
        this.variation('docs_use_proxy_upload')
    );
  }

  @computed
  get docsUseProxyThumbnail() {
    // TODO: TJ-38984 - Remove LD check and use opty as a single source of truth
    return Boolean(
      this.parent.embeddedApp?.optyFeatures?.tmProductsDocsUseProxyThumbnail ||
        this.variation('docs_use_proxy_thumbnail')
    );
  }

  @computed
  get isBtSimplificationPhase1Enabled() {
    return Boolean(
      this.parent.embeddedApp.optyFeatures.isBtSimplificationPhase1Enabled
    );
  }

  @computed
  get isClientDashboardDocumentsAgentsEnabled() {
    return Boolean(
      this.parent.embeddedApp.optyFeatures.isDocumentsAgentsEnabled
    );
  }

  @computed
  get isTMDocsUploadRestrictionsEnabled() {
    return Boolean(
      this.parent.embeddedApp.optyFeatures.isTMDocsUploadRestrictionsEnabled
    );
  }

  @computed
  get restrictCabEventOrigin(): EventOriginValidation {
    // Using launchdarkly instead of opty as opty only work by fetching flags from parent app,
    // but this flag is required during the initializing of CAB
    const flag = this.variation('tm_products_restrict_cab_event_origin');
    return flag === 'off' ? undefined : (flag as EventOriginValidation);
  }

  get enableDisplayAssociationTerms() {
    return Boolean(
      this.parent.embeddedApp?.optyFeatures
        ?.tmProductsDisplayAssociationTerms ||
        this.variation('tm_display_association_terms')
    );
  }
}
