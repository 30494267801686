import * as LDClient from 'launchdarkly-js-client-sdk';
import contextualApi from 'src/api/contextual-api';

export type MockLDClient = Pick<LDClient.LDClient, 'variation' | 'allFlags'>;
declare global {
  interface Window {
    // eslint-disable-line no-shadow
    ldClient: LDClient.LDClient | MockLDClient;
    getLDClient(): Promise<LDClient.LDClient | MockLDClient>;
  }
}

let initializingPromise: null | Promise<LDClient.LDClient | MockLDClient> =
  null;

const getEnvironmentConfigFlagsForUser = async () => {
  /// Historically flags were loaded in the Jinja template by the backend,
  /// but for the embedded experience it was not retrieving the overrides set up
  /// for specific users when using that method, so we had to rely 100% on the LD client
  /// to refetch the flags' values. This replaces that with a new call to the BE to fetch
  /// the correct values instead.
  const { isEmbedded, LAUNCHDARKLY_BOOTSTRAP_FLAGS } = window.Glide;
  if (!isEmbedded) {
    return LAUNCHDARKLY_BOOTSTRAP_FLAGS;
  }
  const { data } = await contextualApi.configurations();
  return data.environments.FLAGS;
};

export const getLDClient = async (): Promise<
  LDClient.LDClient | MockLDClient
> => {
  let context: LDClient.LDContext;
  if (window.Glide && window.Glide.user) {
    context = {
      kind: 'user',
      key: window.Glide.user.id,
      email: window.Glide.user.contact.email,
      is_admin: window.Glide.user.isAdmin,
      access_modes: window.Glide.user.accessModes,
      disable_beta_features:
        window.Glide.user.config &&
        window.Glide.user.config.disableBetaFeatures,
      created_at: window.Glide.user.createdAt,
    };
  } else {
    context = {
      kind: 'user',
      key: 'public',
    };
  }
  if (window.ldClient) {
    return window.ldClient;
  }

  if (initializingPromise) {
    return initializingPromise;
  }

  if (window.Glide.env && !['demo', 'local'].includes(window.Glide.env)) {
    const LAUNCHDARKLY_BOOTSTRAP_FLAGS =
      await getEnvironmentConfigFlagsForUser();
    const useBootstrappedFlags =
      !window.Glide.isEmbedded && Boolean(LAUNCHDARKLY_BOOTSTRAP_FLAGS);
    const disableLdSdk = Boolean(
      LAUNCHDARKLY_BOOTSTRAP_FLAGS?.['flags.disable_ld_client_sdk']
    );
    if (disableLdSdk) {
      initializingPromise = new Promise((r) => {
        const _allFlags = {
          ...window.Glide.CONSTANTS.DEFAULT_FEATURES,
          ...LAUNCHDARKLY_BOOTSTRAP_FLAGS,
        };
        window.ldClient = {
          variation: (key: string) => _allFlags[key],
          allFlags: () => _allFlags,
        };
        r(window.ldClient);
      });
    } else {
      const ldClient = LDClient.initialize(
        window.LAUNCHDARKLY_FRONTEND_KEY!,
        context,
        useBootstrappedFlags
          ? {
              bootstrap: LAUNCHDARKLY_BOOTSTRAP_FLAGS,
            }
          : undefined
      );

      initializingPromise = new Promise((r) => {
        ldClient.on('ready', () => {
          window.ldClient = ldClient;
          r(ldClient);
        });
      });
    }
  } else {
    initializingPromise = new Promise((r) => {
      window.ldClient = {
        variation: (key: string) =>
          (window.Glide.CONSTANTS.DEFAULT_FEATURES ?? {})[key],
        allFlags: () => window.Glide.CONSTANTS.DEFAULT_FEATURES ?? {},
      };
      r(window.ldClient);
    });
  }
  return initializingPromise;
};

window.getLDClient = getLDClient;

export const variation = async (key: string, defaultValue?: any) => {
  try {
    const client = await getLDClient();
    return client.variation(key, defaultValue);
  } catch (e) {
    return false;
  }
};
